<template>
  <div class="exercise-item">
    <div class="exercise-top">
      <div>
        <div class="exercise-title">
          <div class="title-seq">{{ itemIndex + 1 }}、</div>
          <div class="title-content">{{ exerciseData.title }}</div>
          <!-- {{exerciseData}} -->
          <div v-if="picList.length > 0">
            <NImageGroup>
              <NSpace style="width:480px;margin-top:8px">
                <div v-for="(item, index) in picList" :key="index">
                <NImage 
                  width="130"
                  :src="item.url||item.file_url||item"
                />
              </div>
              </NSpace>
            </NImageGroup>
          </div>
        </div>
        <div class="exercise-options">
          <template v-if="Number(exerciseData.type) === questionTypeEnum.FILL_BLANK">
            <div
              v-for="(item, index) in exerciseData.ques_answer"
              :key="index"
              class="option is-answer"
            >
              <NIcon class="option-check" size="20" :color="themeSettings.successColor">
                <CheckmarkSharp />
              </NIcon>
              <div class="option-content">填空答案{{ index + 1 }}：{{ item }}</div>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(item, index) in exerciseData.ques_select"
              :key="index"
              class="option"
              :class="{ 'is-answer': exerciseData.ques_answer.includes(item[0]) }"
            >
              <NIcon class="option-check" size="20" :color="themeSettings.successColor">
                <CheckmarkSharp />
              </NIcon>
              <div class="option-content">{{ item }}</div>
            </div>
          </template>
          <div
            v-if="Number(exerciseData.type) === questionTypeEnum.OPEN"
            style="color: #999;"
          >（由考生上传答案）</div>
        </div>
      </div>
      <div class="exercise-type-info">
        <div>{{ parseFloat(exerciseData.score).toFixed(1) }}分</div>
        <div>{{ questionTypesMap[exerciseData.type] }}</div>
      </div>
    </div>
    <div class="exercise-bottom">
      <NSpace justify="space-between">
        <div class="text-btn">
          <NButton
            type="primary"
            text
            @click="emit('add-click', itemIndex)"
          >在此题后插入新题</NButton>
        </div>
        <NSpace size="small">
          <NButton v-if="permitEdit" size="small" type="primary" ghost @click="EditData(itemIndex,exerciseData)">
            <template #icon>
              <NIcon>
                <EditOutlined />
              </NIcon>
            </template>
            编辑
          </NButton>
          <NButton v-if="permitEdit" size="small" type="primary" ghost @click="emit('exercise-copy', { index: itemIndex, exercise: exerciseData })">
            <template #icon>
              <NIcon>
                <ContentCopyOutlined />
              </NIcon>
            </template>
            复制
          </NButton>
          <NButton size="small" type="error" ghost @click="emit('exercise-del', itemIndex)">
            <template #icon>
              <NIcon>
                <TrashOutline />
              </NIcon>
            </template>
            删除
          </NButton>
          <NButton size="small" type="success" ghost @click="emit('exercise-pre', itemIndex)">
            <template #icon>
              <NIcon>
                <ArrowUpSharp />
              </NIcon>
            </template>
            上移
          </NButton>
          <NButton size="small" type="success" ghost @click="emit('exercise-next', itemIndex)">
            <template #icon>
              <NIcon>
                <ArrowDownSharp />
              </NIcon>
            </template>
            下移
          </NButton>
          <NButton size="small" type="warning" ghost @click="emit('exercise-top', itemIndex)">
            <template #icon>
              <NIcon>
                <VerticalAlignTopOutlined />
              </NIcon>
            </template>
            最前
          </NButton>
          <NButton size="small" type="warning" ghost @click="emit('exercise-bottom', itemIndex)">
            <template #icon>
              <NIcon>
                <VerticalAlignBottomOutlined />
              </NIcon>
            </template>
            最后
          </NButton>
        </NSpace>
      </NSpace>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { CheckmarkSharp, TrashOutline, ArrowDownSharp, ArrowUpSharp } from '@vicons/ionicons5';
  import { VerticalAlignTopOutlined, VerticalAlignBottomOutlined, ContentCopyOutlined, EditOutlined } from '@vicons/material';

  import questionTypesMap, { questionTypeEnum } from '@/enumerators/question-type-map.js'; 
  import themeSettings from '@/settings/theme-settings.js'; 

  const props = defineProps({
    itemIndex: {
      type: Number,
      required: true
    },
    exerciseData: {
      type: Object,
      required: true
    },
    permitEdit: {
      type: Boolean,
      default: false
    }
  });

  const picList = computed(() => {
    const data = props.exerciseData;
    if (Array.isArray(data['upload_list']) && data['upload_list'].length > 0) {
      return data['upload_list'];
    } else if (Array.isArray(data['Exercises[upload]']) && data['Exercises[upload]'].length > 0) {
      return data['Exercises[upload]'];
    } else {
      return [];
    }
  });

  // 点击编辑时处理数据
  function EditData(itemIndex, exerciseData) {
    let data = {
      ...exerciseData
    };
    if (
      (!Array.isArray(data['upload_list']) || data['upload_list'].length === 0) && 
      (Array.isArray(data['Exercises[upload]']) && data['Exercises[upload]'].length > 0)
    ) {
      data['upload_list'] = data['Exercises[upload]'];
    }
    emit('exercise-edit', { index: itemIndex, exercise: data })
  }
  const emit = defineEmits([
    'add-click',
    'exercise-del',
    'exercise-pre',
    'exercise-next',
    'exercise-top',
    'exercise-bottom',
    'exercise-edit',
    'exercise-copy'
  ]);
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";

  .exercise-item {
    padding: 20px 25px 10px;
    border-bottom: 1px dashed #ddd;
    transition: background-color .15s;

    &:hover {
      background-color: #fafafa;

      .exercise-bottom {
        visibility: visible;
      }
    }
  }

  .exercise-top {
    position: relative;
    padding-right: 100px;
  }

  .exercise-title {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 15px;
    word-break: break-all;

    .title-seq {
      float: left;
    }

    .title-content {
      white-space: pre-wrap;
    }
  }

  .exercise-options {
    white-space: pre-wrap;

    .option {
      position: relative;
      margin-bottom: 4px;

      &.is-answer {
        background-color: #FDE8CC;

        .option-check {
          display: block;
        }
      }
    }
  }
  .option-check {
    position: absolute;
    left: 10px;
    top: 2px;
    display: none;
  }
  .option-content {
    margin-left: 60px;
    word-break: break-all;
  }

  .exercise-type-info {
    position: absolute;
    right: 0;
    top: 0;
    text-align: right;
    color: @theme-color;
  }

  .exercise-bottom {
    visibility: hidden;
  }
  .text-btn {
    margin-top: 5px;
  }
</style>